body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F3F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse {
  0%, 100% {
    color: initial;
    font-weight: normal;
    transform: scale(1);
  }
  50% {
    color: red;
    font-weight: bold;
    transform: scaleY(1.2);
  }
}

.pulsating-text {
  animation: pulse 0.4s infinite;
  transition: color 0.2s, font-weight 0.2s, transform 0.2s;
}